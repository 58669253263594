@keyframes slide {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 50% 0;
  }

  100% {
    background-position: 0 0;
  }
}
